/* Contact.css */

.ContactUS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  width: 80%;
  margin: 0 auto; 
}

.contact-heading {
  margin-bottom: 30px;
}

.contact-heading h1 {
  font-size: 70px;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  text-decoration: solid;
  /* -webkit-text-stroke: 1px #000000; */
  /* Gradient Background */
  background: linear-gradient(270deg, #fb12b5, #3a5eff);
  /* Adjust colors as needed */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  /* Animation */
  animation: gradientAnimation 5s infinite alternate;
}

/* Animation Keyframes */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.contact-block {
  display: flex;
  max-height: 600px;
  background: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.leftside {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f1f8fb; /* Background color for leftside */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  transition: box-shadow 0.3s ease; /* Add transition to box shadow */

}

.leftside:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
}

.leftside h2 {
  font-size: 24px;
  margin-bottom: 30px; /* Increase margin-bottom as needed */
  font-family: 'Arial', sans-serif;
  color: #333; /* Adjust color as needed */
  text-align: center; /* Center the text */
  line-height: 1.5; /* Increase line height for better readability */
  letter-spacing: 1px; /* Add letter spacing for better visibility */
}

.leftside form {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.leftside .form-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* .leftside label {
  color: #555;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
} */

.leftside input {
  width: 98.5%;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  margin-bottom: 1em;
  justify-content: center;
  /* transform: scaleX(2); */
  background-color: #f1f8fb;
  border-color: transparent;
  border-bottom: 1px solid #8f8f8f;
  resize: none;
}

.leftside input::placeholder{
  font-size: larger; /* Adjust font size as needed */
}



.leftside input:focus {
  border-color: #007bff; /* Change border color on focus */
}

.leftside button {
  margin-top: 20px;
  padding: 1em 1rem;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  font-size: large;
  border: none;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transitions for background color and transform */
}

.leftside button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Add a subtle scale effect on hover */
}

.rightside {
  width: 50%;
}

.picture {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ... other styles ... */


/* Existing styles... */

/* Responsive styles for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .ContactUS {
    width: 90%; /* Adjust width for smaller screens */
    padding-right: 0;
  }

  .contact-heading h1 {
    font-size: 40px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  .leftside{
    width: 100%;
  }
  .rightside{
    width: 0%;
  }
  .leftside h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
    margin-bottom: 20px;
  }

  .leftside form {
    margin-top: 15px;
  }

  .leftside label {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .leftside input,
  .leftside textarea {
    width: 100%;
  }

  .picture {
    display: none; /* Hide the image */
  }
}

/* ... Other existing styles ... */

.picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Add a transition effect on transform */
}

.picture:hover img {
  transform: scale(1.1); /* Increase scale on hover */
}

/* Existing styles... */
