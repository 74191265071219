/* Navbar.css */

/* Common styles for both affixed and non-affixed states */

.nav {
  display: flex;
  width: 100%;
  height: 90px;
  position: fixed;
  line-height: 65px;
  z-index: 100; /* Added solid to the border */
  background-color: transparent; /* Set default background color to transparent */
  transition: all 0.4s ease;
}

.nav .logo {
  float: left;
  padding-right: 60px;
}

.nav .logo img {
  margin-top: -40px;
  margin-left: -30px;
  float: left;
  /* width: 150px; 
  height: 50px;  */
  padding-left: 0rem; /* Adjusted padding */
  object-fit: cover;
  transform: scale(0.38) ;

  /* Increase the contrast of the logo */
  filter: contrast(150%);
}

.nav .logo a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
}

.nav .logo a:hover {
  color: #00E676;
}

.nav .main_list {
  height: 90px;
  float: right;
}

.nav .main_list ul {
  width: 100%;
  height: 90px;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 40vw;
}

.nav div.main_list ul li {
  height: 90px;
  margin-right: 50px; /* Adjusted padding */
}

.nav div.main_list ul li {
  text-decoration: none;
  color: #ffffff;
  line-height: 90px;
  font-size: 1.5rem; /* Adjusted font size */
  transition: color 0.2s ease-in;
  cursor: pointer;
}

.nav div.main_list ul li a {
  text-decoration: none;
  color: #ffffff;
  line-height: 90px;
  font-size: 1.5rem; /* Adjusted font size */
  transition: color 0.2s ease-in;
}

.nav div.main_list ul li:hover {
  color: #000000;
  transition: all 0.2s ease-in;
}

.nav div.main_list ul li a:hover {
  color: #000000;
  transition: all 0.2s ease-in;
}

.nav {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* Affix styles */
.affix {
  padding: 0;
  background-color: #F1F8FB;
}

.affix div.main_list ul li {
  text-decoration: none;
  color: #000000;
  line-height: 90px;
  font-size: 1.5rem; /* Adjusted font size */
  transition: color 0.2s ease-in;
}

.affix div.main_list ul li a {
  text-decoration: none;
  color: #000000;
  line-height: 90px;
  font-size: 1.5rem; /* Adjusted font size */
  transition: color 0.2s ease-in;
}

.affix div.main_list ul li:hover {
  color: #000000;
}

.affix div.main_list ul li a:hover {
  color: #000000;
}

.theme_switch {
  max-width: 100px;
  display: flex;
  margin-top: 32px;
  margin-left: 0px;
  padding: 0;
  float: right;
}

.react-switch-bg {
  height: 80px;
}

.react-switch-handle {
  border: 2px solid #ffffff;
}

@media only screen and ( max-width: 1440px) and (min-width : 1025px){
  .nav .main_list ul {
    margin-left: 400px;
  }
}

@media only screen and ( max-width: 1025px) and (min-width : 768px){
  .nav .main_list ul {
    margin-left: 0px;
  }
}


@media only screen and (max-width: 768px) {
  .nav {
    height: 70px;
    line-height: 50px;
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }

  .nav .logo {
    float: left;
    padding-right: 20px;
  }

  .nav .logo img {
    margin-top: -40px;
    margin-left: -100px; /* Adjusted the margin-left to align properly */
  }

  .nav div.main_list {
    margin-top: 0; /* Removed the negative margin-top */
    margin-right: 0;
    text-align: center;
    
  }

  .nav div.main_list ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row; /* Make the main list items stay in a single line */
    align-items: center;
    justify-content: center; /* Center the main list items */
    margin-top: -20px;
    
  }

  .nav div.main_list ul li {
    margin-right: 0; /* Adjusted margin for better spacing */
    font-size: 1rem;
  }

  .nav div.main_list ul li a {
    font-size: 1rem;
  }

  .theme_switch{
    margin-bottom:25px ;
    margin-left: -50px;
  }
  .nav .contact{
    padding-right: 30px;
  }
}



/* Add these styles to Navbar.css */

.menu-icon {
  display: none; /* Hide by default for larger screens */
  cursor: pointer;
  margin-top: 15px;
}

.menu-icon div {
  width: 30px;
  height: 5px;
  background-color: rgb(1, 1, 1);
  margin: 5px 0;
  transition: 0.4s;
}

/* Display the menu on smaller screens */
@media only screen and (max-width: 768px) {
  .menu-icon {
    display: block;
    margin-right: 20px;
  }
  .nav .main_list{
    height: 60px;
    padding-top: 10px;
  }

  .logo{
    margin-right: 30px;
    margin-top: -15px;
  }

  .theme_switch{
    margin-top: 22.5px;
    display: flex;
  }

  .main_list {
    display: none; /* Hide the links by default */
    width: 100%;
    position: absolute;
    top: 70px; /* Adjust as needed based on your design */
    left: 0;
    right: 0;
  }

  .affix .main_list{
    background-color: #F1F8FB;
    transition: color 0.2s ease-in;
  }

  .main_list.open {
    display: block;
  }

  .main_list ul {
    display: flex;
    flex-direction: column;
    width: 100%; /* Make the dropdown menu full width */
    text-align: center; /* Center the menu items */
    font-weight: 600;
  }

  .main_list ul li {
    margin: 2px -2px 10px 0px;
    width: 100%; /* Make each menu item full width */
  }
}


@media only screen and (max-width: 480px){
  .theme_switch{
    margin-right: 5px;
  }
  .nav .main_list{
    height: 60px;
  }

  .menu-icon {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 390px){

  .theme_switch{
    margin-right: 20px;
  }

  .menu-icon {
    display: block;
    margin-right: 30px;
  }
}