/* Styling for the background image and details container */

.event-details {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  padding: 40px; /* Add padding for better readability */
  border-radius: 15px;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  max-width: 100%;
  z-index: 1; /* Ensure details appear on top of the image */
}

.event-img-detail {
  position: relative;
  overflow: hidden; /* Ensure child elements don't overflow */
  /* object-fit: cover; */
}

.event-img-detail img {
  opacity: 0.7;
  object-fit: cover;
  width: 100%;
}

.event-description-container {
  display: flex;
  flex-direction: column;
  color: #333; /* Set background color */
  /* padding: 20px; */
  border-radius: 10px;
  margin: 20px auto; /* Center the details horizontally */
  width: 95%; /* Adjust width as needed */
}

.event-description {
  background: #f1f8fb;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto; /* Center the details horizontally */
  width: 95%; /* Adjust width as needed */
  position: relative;
}

/* Styling for the event name */
.event-name {
  position: relative;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem; /* Adjust font size as needed */
  color: #fff;
}

.event-name h1 {
  font-size: 4rem;
  font-family: "Droid Serif", serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* background: rgba(0, 0, 0, 0.5); Background overlay for better visibility */
  padding: 20px; /* Add padding for better readability */
  border-radius: 15px; /* Optional: Add border-radius for a subtle rounded look */
  transform: translateY(-20%);
}
/* Styling for the event date and venue */
.event-date-venue:hover {
  transform: scale(1.1);
  color: #f1f8fb;
}

.event-date-venue h2 {
  font-size: 1.5rem;
  font-family: Basis-regular;
  font-weight: bold;
  color: #fff;
  opacity: 0.9; /* Adjust opacity as needed for visibility */
  /* transform: translateY(%); */
  font-size: 30px;
  text-align: center;
}
.event-date-venue {
  margin-top: 50px; /* Adjust as needed */
  margin-bottom: 30px;
}

.registration-options button:hover {
  background-color: #333;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* transform: scale(1.02); */
}

.registration-options button {
  background-color: #f1f8fb; /* Mint color */
  color: #333;
  padding: 20px 30px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 15px;
  margin-top: 10px;
  transform: translateY(25px);
}

/* Styling for the "Why Attend?" and "Who Should Attend?" cards */
.event-why ul,
.event-who ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: 1rem;
}

.event-why ul li,
.event-who ul li {
  flex: 0 0 calc(100% / 3 - 20px); /* Calculate the width dynamically */
  margin: 10px;
  padding: 20px;
  border-radius: 15px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0 10px 20px 0 rgba(0, 69, 255, 0.06);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.event-who ul li:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}
.event-why ul li:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.event-why h1,
.event-who h1 {
  font-size: 1.7em;
  margin-bottom: 10px;
  font-family: "Droid Serif", serif;
  font-weight: bold;
  text-transform: uppercase;
}

.event-why,
.event-who {
  text-align: center;
  margin: 20px 0;
}

.event-intro-desc p {
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 20px;
  text-align: center;
  font-family: Basis-regular;
}

.event-intro-title h1 {
  font-size: 2rem;
  font-family: "Droid Serif", serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}

.event-description .event-video {
  /* Styles for your video section */
}

.event-desc-img {
  display: flex;
  justify-content: space-between;
}

/* Additional adjustments for better responsiveness */
@media only screen and (max-width: 768px) {
  .event-why ul li,
  .event-who ul li {
    flex: 0 0 calc(50% - 20px); /* Adjust for two columns on smaller screens */
  }
  .event-details {
    width: 90%; /* Adjust as needed */
  }
  .event-name h1 {
    font-size: 20px;
  }
  .event-date-venue {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

/* Additional adjustments for better mobile view */
@media only screen and (max-width: 480px) {
  .event-details {
    max-width: 100%; /* Adjust width for smaller screens */
    /* font-size: 0.5rem; */
    padding: 0;
    padding-bottom: 40px;
    top: 60%;
  }

  .event-name {
    bottom: 0;
  }

  .event-name h1 {
    font-size: 1rem; /* Adjust font size for smaller screens */
    /* padding: 0%; */
    margin-top: 50px;
    border-radius: 8px; /* Adjust border-radius for a subtle rounded look */
    transform: translateY(
      0%
    ); /* Adjust translateY for better vertical alignment */
  }

  .event-date-venue h2 {
    font-size: 0.7rem; /* Adjust font size for smaller screens */
    margin-top: -30px;
    /* bottom: 20px; Adjust bottom position for better spacing */
  }

  .registration-options {
    margin-top: -40px;
    bottom: 0; /* Adjust bottom position for better spacing */
  }

  .registration-options button {
    padding: 10px 15px; /* Adjust button padding for better touch responsiveness */
    font-size: 0.7rem; /* Adjust font size for smaller screens */
    margin-top: -40px;
  }

  .event-description h1 {
    font-size: 18px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  .event-description p {
    font-size: 16px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  .event-who ul {
    font-size: 16px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  .event-why ul {
    font-size: 16px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  /* Centering event name and date-venue */

  /* .event-date-venue {
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  } */
}
