.aboutUs-container{
    display: inline;
}

.aboutUs-heading{
    font-size: 70px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  text-decoration: solid;
  /* -webkit-text-stroke: 1px #000000; */
  /* Gradient Background */
  background: linear-gradient(270deg, #fb12b5, #3a5eff);
  /* Adjust colors as needed */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  /* Animation */
  animation: gradientAnimation 5s infinite alternate;
}

/* Animation Keyframes */
@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

.Vision,
.Mission{
    display: flex;
    transform: scale(0.8);
    flex-direction: row;
    justify-content: center; /* Centers the div horizontally */
    align-items: center;     /* Centers the div vertically */
    height: 50vh;
}

.section-image{
    width: 100vw;
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    height: auto;
    max-height: 400px; /* Set a maximum height for the image */
    object-fit: cover; /* Maintain aspect ratio while covering the container */
    border-radius: 10px; /* Add a border-radius for rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.aboutUs-content{
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 100%;
    background-color: #f9f9f9; /* Add a light background color */
    border-radius: 10px; /* Add border-radius for rounded corners */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin-top: 10px; /* Add margin at the top for separation */
    
}
.aboutUs-content:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); /* Add a more prominent box shadow on hover */
}

.aboutUs-container h1{
    margin: 10px 0;
  font-size: 4rem;
  font-weight: 700;
  /* color: rgb(34, 89, 255); */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  font-family: 'Droid Serif', serif;
}

.aboutUs-container p{
    font-family: Basis-regular;
    font-size: 1.4rem;
    line-height: 2.3rem;
    letter-spacing: .03125rem;
    color: #5E6162;
    opacity: .9;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .Vision,
    .Mission {
        height: auto; /* Adjust height for smaller screens */
        margin: 20px;
        transform: scale(1);
    }

    .aboutUs-heading {
        font-size: 40px; /* Reduce font size for the title */
        padding-top: 20px; /* Adjust spacing for the title */
    }

    .section-image {
        display: none; /* Hide image on smaller screens */
    }

    .aboutUs-content {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
        margin-bottom: 10px;
    }
}