.simple-carousel {

  position: relative;
  max-width: 100%;
  margin: auto;
  height: 900px;
  justify-content: center;
  overflow: hidden;
}

.simple-carousel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 900px;
  background: linear-gradient(to bottom, rgba(140, 15, 175, 0.677), rgba(39, 39, 233, 0.6));
  pointer-events: none;
  opacity: 0.7;

}


.simple-carousel img {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.simple-carousel button {
  position: absolute;
  top: 55%;
  transform: translateY(-250%);
  background: none;
  border: none;
  font-size: 2em;
  color: white;
  cursor: pointer;
}

.simple-carousel button:first-child {
  left: 0;
}

.simple-carousel button:last-child {
  right: 0;
}

@-webkit-keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%-100px);
    transform: translateY(-50%-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.fadeInUpSD {
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
}

/* .carousel-content {
  display: flex;
  /* background-color: rgb(54, 54, 54, 0.5); */
  /* left: 10%;
  opacity: 1; */
  /* transition: opacity 3s ease-in-out; */
/*  max-width: 70%;
} */

.simple-carousel .carousel-inner-content {
  background-color: rgb(54, 54, 54, 0.5);
  position: relative;
  top:50px;
  /* z-index: 2; */
  margin: 50px;
  left: 6%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  /* width: 80%; */
  height: 450px;
  transform: translateY(-165%);
  /* transform: translateX(20%); */
}


.simple-carousel .carousel-inner-content h3 {
  position: relative;
  animation-name: fadeInUpSD;
  animation-duration: 1s;
  top:10%;
  /* left: 5%; */
  transform: translateY(-50%);
  color: aliceblue;
  font-size: 30px;
}

.simple-carousel .carousel-inner-content h2 {
  position: relative;
  animation-name: fadeInUpSD;
  animation-duration: 2s;
  top: 0%;
  /* left: 5%; */
  /* background-color: rgb(54, 54, 54, 0.5); */
  /* margin-top: 100px; */
  transform: translateY(-50%);
  color: aliceblue;
  font-size: 80px;
}

.simple-carousel .carousel-inner-content p{
  position: relative;
  animation-name: fadeInUpSD;
  animation-duration: 3s;
  text-align: center;
  /* top: 20%; */
  top: -15%;
  /* left: 5%; */
  transform: translateY(-50%);
  color: aliceblue;
  font-size: 30px;
  max-width: 60%;
}


@media screen and (max-width: 768px) {
  .simple-carousel {
    height: 500px; /* Adjust the height as needed for smaller screens */
  }

  .simple-carousel::before {
    height: 500px; /* Adjust the height as needed for smaller screens */
  }

  .simple-carousel .carousel-inner-content {
    top: 5%; /* Adjust the top position as needed for smaller screens */
    max-width: 100%;
    left: 0%;
    margin-left: 10px;
    margin-right: 10px;
    height: 250px; 
    transform: translateY(-325%);
  }
  .simple-carousel .carousel-inner-content h3 {
    margin-top: 10px;
    font-size: 15px;
    margin-left: 20px;
  }

  .simple-carousel .carousel-inner-content h2 {
    font-size: 25px; /* Adjust the font size as needed for smaller screens */
    margin-top: 20px;
    top: 5%;
    margin-left: 20px;
  }

  .simple-carousel .carousel-inner-content p {
    font-size: 15px; /* Adjust the font size as needed for smaller screens */
    margin-top: 15px;
    top: 10%;
    margin-left: 20px;
  }

  .simple-carousel button {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    
  }
}