.event-pastdesc p {
  font-size: 20px;
}

.event-video-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjust the width as per your requirement */
  padding-top: 56.25%; /* Aspect ratio of 16:9 */
}

.event-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

iframe {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.event-desc-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 5px;
}

.event-pastdesc {
  flex-basis: 60%;
  font-size: 1rem;
  padding: 5px;
  font-family: sans-serif;
}
.event-pastimg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
}

.carousel-wrapper {
  width: 700px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
  border-radius: 20px;
  margin: 50px auto 20px;
}

.carousel-container {
  overflow: hidden;
  margin: 0 auto;
}

.carousel {
  display: flex;
  width: 2100px;
  animation: sliding 12s infinite;
  div {
    width: 700px;
    height: 500px;
    background-size: cover;
    background-position: center;
  }
  &:hover {
    animation-play-state: paused;
  }
  .image-one {
    background-image: url("https://images.unsplash.com/photo-1480936600919-bffa6b7ecf1e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  }
  .image-two {
    background-image: url("https://images.unsplash.com/photo-1475053081036-c8eb31d187b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  }
  .image-three {
    background-image: url("https://images.unsplash.com/photo-1525278070609-779c7adb7b71?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  }
}

@keyframes sliding {
  30% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-700px);
  }
  65% {
    transform: translateX(-700px);
  }
  70% {
    transform: translateX(-1400px);
  }
  98% {
    transform: translateX(-1400px);
  }
  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 780px) {
  .event-pastdesc {
    font-size: 0.8rem;
  }
  .event-pastimg {
    width: 100%;
    align-items: center;
  }

  .carousel-wrapper {
    width: 400px;
    height: 250px;
  }

  .carousel {
    width: 1200px;
    > div {
      width: 400px;
      height: 250px;
    }
  }

  @keyframes sliding {
    30% {
      transform: translateX(0);
    }
    35% {
      transform: translateX(-400px);
    }
    65% {
      transform: translateX(-400px);
    }
    70% {
      transform: translateX(-800px);
    }
    98% {
      transform: translateX(-800px);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@media only screen and (max-width: 480px) {
  .event-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-desc-img {
    display: block;
  }
  .event-pastdesc {
    font-size: 0.8rem;
  }
  .event-pastimg {
    width: 100%;
  }

  .carousel-wrapper {
    width: 300px;
    height: 200px;
  }

  .carousel {
    width: 900px;
    > div {
      width: 300px;
      height: 200px;
    }
  }

  @keyframes sliding {
    30% {
      transform: translateX(0);
    }
    35% {
      transform: translateX(-300px);
    }
    65% {
      transform: translateX(-300px);
    }
    70% {
      transform: translateX(-600px);
    }
    98% {
      transform: translateX(-600px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
