/* App.css */
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }
  
  .App {
    transition: background-color 0.3s ease;
  }
  
  .light {
    background-color: #fff;
    color: #000;
  }
  
  .dark {
    background-color: #242424;
    color: #fff;
  }
  
  