/* ... (Your existing styles) ... */

.card-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  transform: scale(0.98);
  margin: 10px;
  margin-top: 100px;
}

.founder-card-heading{
  font-size: 70px;
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  text-decoration: solid;
  /* -webkit-text-stroke: 1px #000000; */
  /* Gradient Background */
  background: linear-gradient(270deg, #fb12b5, #3a5eff);
  /* Adjust colors as needed */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  /* Animation */
  animation: gradientAnimation 5s infinite alternate;
}

/* Animation Keyframes */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.card {
  position: relative;
  max-width: calc(100% / 4);
  height: fit-content;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  transition: height 0.5s ease-in-out;
  background-color: #f1f8f8;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  border-radius: 3%;
  
}

.card:hover {
  height: fit-content;

}
/* .card:hover {
  transition: height 0.5s ease-in-out;
} */

.card .image {
  position: relative;
  width: auto;
  height: auto;
  margin-top: -30%;
  border-radius: 3%;
  align-self: center;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

/* .card:hover .image {
  top: -10%;
} */

.card .image img {
  max-width: 100%;
  border-radius: 50%;
}

.content {
  position: relative;
  top: -60px;
  padding: 10px 15px;
  color: #111;
  text-align: center;
  height: 0;
  transition: height 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
  height: 100%;
}

.card .founder-header {
  text-align: center;
  margin-top: 80px;
  visibility: visible;
  opacity: 1;
}

.card .founder-header h3{
  margin: 10px 0;
  font-size: 1.3 rem;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Droid Serif', serif;
}
.founder-header p{
  font-family: Basis-regular;
  font-size: 1.25rem;
  line-height: 1rem;
  letter-spacing: .03125rem;
  color: #5E6162;
  opacity: .9;
  margin-bottom: 0;
}

.founder-description p{
  font-family: Basis-regular;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: .04125rem;
  color: #5E6162;
  opacity: .9;
  margin-bottom: 0px;
  justify-content: center;
}

.card:hover .founder-header {
  text-align: center;
  margin-bottom: 15px;
  visibility: visible;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.card .founder-description {
  text-align: center;
  margin-bottom: 15px;
  visibility: hidden;
  opacity: 0;
}

.card:hover .founder-description {
  text-align: center;
  margin-bottom: 15px;
  visibility: visible;
  opacity: 1;
  transition: 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icons a {
  margin: 0 10px;
  color: #333;
  font-size: 30px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #0077b5;
  font-size: 40px;
}

.content {
  display: inline;
}

.card-container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  margin-top: 100px;
}

.card {
  position: relative;
  max-width: calc(100% / 4); /* Adjust the percentage as needed for responsiveness */
  height: 200px;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  transition: height 0.5s ease-in-out;
  background-color: #f1f8f8;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
  border-radius: 3%;
}
@media screen and (max-width: 768px) {
  .card {
    max-width: 100%;
    margin-bottom: 50px;
  }

  .card-container {
    margin-top: 100px; /* Adjust the margin-top as needed for responsiveness */
  }

  .founder-card-heading {
    font-size: 40px; /* Reduce font size for the title */
    padding-top: 20px; /* Adjust spacing for the title */
  }

  .card:hover {
    height: fit-content;
  }
}