.footer-sec {
    margin-top: 100px;
    padding-bottom: 30px;
    text-align: center;
    background-color: #d2edfa;
}

.footer-sec h2 {
    padding-bottom: 20px;
    padding-top: 30px;
    color: #333;
}

.footer-sec p{
    color: #333;
}

.footer-sec button {
    background: none;
    margin: 0px 30px;
    border: none;
    font-size: 2em;
    color: #626262;
    cursor: pointer;
}

.footer-sec button:hover{
    color: #0077b5;
    transform: scale(1.3);
    transition: color 0.3s, transform 0.3s ease-in-out;
}

@media (max-width: 768px) {

    .footer-sec {
        padding-bottom: 10px;
    }

    .footer-sec h2  {
        padding-bottom: 0;
        padding-top: 15px;
    }
    
    .footer-sec button{
        font-size: 1.3em;
        margin: 0px 10px;
    }
}